import useSWR from 'swr';
import ow from 'oceanwind';
import { FeedbackFish } from '@feedback-fish/react';

import Login from './components/Login';
import Header from './components/Header';
import Loading from './components/Loading';
import Playlists from './components/Playlist';
import ErrorMessage from './components/ErrorMessage';
import PlaylistConflictSelector from './components/PlaylistConflictSelector';
import { dateToLocalString } from './utils/dates';

export const API_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://api.recoverweekly.com'
    : 'http://localhost:5000/dev';

const fetcher = (url: string) =>
  fetch(url, { credentials: 'include' }).then(res => {
    if (res.ok) return res.json();
    throw new Error(res.statusText);
  });

export default function App() {
  const { data, error, revalidate, mutate } = useSWR(API_URL + '/playlists', fetcher, {
    revalidateOnFocus: false,
  });

  const resolveConflictPlaylist = async (playlistId: string) => {
    // clear SWR cache to show loading
    mutate(null, false);
    await fetch(API_URL + `/conflict`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({ playlistId }),
    });
    // Revalidate playlists
    revalidate();
  };

  if (error) return <Login />;
  if (!data) return <Loading />;
  const { user, playlists, conflicts, isSupporter, joinedOn, errorMessage } = data;
  return (
    <>
      <Header
        userId={user.id}
        username={user.display_name}
        profileImage={
          data.playlists.length ? data.playlists[0].images[0].url : [...user.images].pop()?.url
        }
        newUser={playlists.length <= 1}
        hasConflicts={!!conflicts}
        hasError={!!errorMessage}
      />
      {errorMessage && (
        <main className={ow`px-4 py-4 mx-auto md:max-w-xl`}>
          <ErrorMessage userId={user.id} errorMessage={errorMessage} />
        </main>
      )}
      {playlists.length > 0 && <Playlists playlists={playlists} />}
      {conflicts ? (
        <PlaylistConflictSelector
          conflicts={conflicts}
          onPlaylistSelected={resolveConflictPlaylist}
        />
      ) : playlists.length === 4 && !isSupporter ? (
        <div className={ow`px-4 mx-auto md:max-w-xl pb-10`}>
          Unfortunately any recovered playlists from more than 4 weeks old have possibly been
          deleted, this is a free service, so we need to keep our running costs as low as possible.
          You can request unlimited saved playlists by{' '}
          <FeedbackFish projectId="fe87f70832d990" userId={user.id}>
            <button className={ow`underline`}>contacting us</button>
          </FeedbackFish>{' '}
          and supporting Recover Weekly.
        </div>
      ) : (
        playlists.length > 0 && (
          <div className={ow`text-center pb-6`}>
            {playlists.length === 1 ? (
              <>
                No more playlists, your Recover Weekly account was only created this week.
                <br />
                We can't recover any playlists from the past as we didn't have access to your
                Discover Weekly then.
              </>
            ) : (
              <>
                You joined Recover Weekly on {dateToLocalString(joinedOn)}.
                <br /> Sorry, we can't recover any playlists before that week.
              </>
            )}
          </div>
        )
      )}
    </>
  );
}
