import { FeedbackFish } from '@feedback-fish/react';
import ow from 'oceanwind';

export default function ErrorMessage({
  userId,
  errorMessage,
}: {
  userId: string;
  errorMessage: 'discover-weekly-not-found' | string;
}) {
  return (
    <div className={ow`p-4 mb-12 bg-red-300 rounded`}>
      {errorMessage === 'discover-weekly-not-found' ? (
        <>
          We couldn't find your <strong>Discover Weekly</strong> playlist in{' '}
          <a
            className={ow`underline`}
            href="https://open.spotify.com/collection/playlists"
            target="_blank"
            rel="noreferrer noopener"
          >
            Your Library
          </a>{' '}
          on Spotify
          <div className={ow`pt-4 text-sm`}>
            <strong>Troubleshooting:</strong>
            <ul className={ow`list-disc pl-4`}>
              <li>
                Have you deleted the <strong>Discover Weekly</strong> playlist in{' '}
                <a
                  className={ow`underline`}
                  href="https://open.spotify.com/collection/playlists"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  Spotify
                </a>
                ? <br />
                <i>
                  You can recover deleted playlists here:{' '}
                  <a
                    className={ow`underline`}
                    href="https://www.spotify.com/account/recover-playlists/"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    https://spotify.com/account/recover-playlists/
                  </a>
                </i>
              </li>
              <li>
                Have you renamed the <strong>Discover Weekly</strong> playlist in{' '}
                <a
                  className={ow`underline`}
                  href="https://open.spotify.com/collection/playlists"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Spotify
                </a>
                ? <br />
                <i>
                  We find your "Discover Weekly" playlist by looking for it in your Spotify
                  playlists.
                </i>
              </li>
              <li>
                Do you have over 500 playlists on your Spotify?
                <br /> <i>We can't support more that many playlists.</i>
              </li>
            </ul>
            <div className={ow`pt-4`}>
              We need your <strong>Discover Weekly</strong> playlist so we can back it up every
              week.
              <br />
              Come back here when you've restored your <strong>Discover Weekly</strong> playlist.
            </div>
            <div className={ow`pt-4`}>
              If you would like to revoke our app from accessing your Spotify data, you can do so in
              the{' '}
              <a
                className={ow`underline`}
                href="https://www.spotify.com/account/apps/"
                rel="noreferrer noopener"
                target="_blank"
              >
                Spotify Manage Apps settings
              </a>
            </div>
          </div>
        </>
      ) : (
        <>
          <strong>Unexpected Error:</strong> {errorMessage}
          <div className={ow`pt-4 text-sm`}>
            <strong>Troubleshooting:</strong>
            <ul className={ow`list-disc pl-4`}>
              <li>Try refresh this page</li>
              <li>Try logging out of your Spotify account and logging back in</li>
              <li>Clear your cookies for recoverweeky.com</li>
            </ul>
            <div className={ow`mt-2`}>
              If this problem persists, please{' '}
              <FeedbackFish projectId="fe87f70832d990" userId={userId}>
                <button className={ow`underline`}>contact us</button>
              </FeedbackFish>
              {'.'}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
