import { FeedbackFish } from '@feedback-fish/react';
import { API_URL } from '../App';
import ow from 'oceanwind';

interface Props {
  profileImage: string | undefined;
  username: string;
  userId: string;
  newUser: boolean;
  hasConflicts: boolean;
  hasError: boolean;
}

export default function Header({
  profileImage,
  username,
  userId,
  newUser,
  hasConflicts,
  hasError,
}: Props) {
  return (
    <div className={ow`w-full mx-auto`}>
      <div className={ow`relative flex flex-col items-center justify-center w-full mx-auto`}>
        <div className={ow`relative bg-gray-200 w-full`}>
          <div className={ow`relative flex flex-col items-center justify-center w-full mx-auto`}>
            <div className={ow`w-full px-4 py-5 mx-auto md:px-24 lg:px-8 bg-gray-200`}>
              <div
                className={ow`relative flex items-center justify-center md:justify-between max-w-6xl mx-auto`}
              >
                <a href="/" className={ow`inline-flex items-center`}>
                  <h1 className={ow`m-auto lg:ml-2 mr-8 text-xl font-bold text-gray-900`}>
                    Recover Weekly
                  </h1>
                </a>
                <ul className={ow`flex items-center space-x-8`}>
                  <li className={ow`hidden sm:block`}>
                    <FeedbackFish projectId="fe87f70832d990" userId={userId}>
                      <button className={ow` font-medium tracking-wide text-gray-700`}>
                        Give us feedback
                      </button>
                    </FeedbackFish>
                  </li>
                  <li>
                    <a
                      href={API_URL + '/logout'}
                      // className={ow`inline-flex items-center justify-center h-12 px-6 tracking-wide text-white rounded shadow-md bg-indigo-500 hover:bg-indigo-500`}
                      className={ow` font-medium tracking-wide text-gray-700`}
                    >
                      Log out
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={ow`absolute inset-x-0 bottom-0`}>
            <svg
              viewBox="0 0 224 12"
              fill="currentColor"
              preserveAspectRatio="none"
              className={ow`w-full -mb-1 text-whit`}
            >
              <path
                fill="#fff"
                d="M0,0 C48.8902582,6.27314026 86.2235915,9.40971039 112,9.40971039 C137.776408,9.40971039 175.109742,6.27314026 224,0 L224,12.0441132 L0,12.0441132 L0,0 Z"
              ></path>
            </svg>
          </div>
          <div className={ow`px-4 py-10 mx-auto md:max-w-full max-w-screen-xl md:px-24 lg:px-8`}>
            <div className={ow`relative max-w-2xl sm:mx-auto text-center`}>
              {profileImage && (
                <p
                  className={ow`flex flex-col justify-center items-center w-full mb-4 md:flex-row md:px-16`}
                >
                  <img
                    style={{ width: '160px', height: '160px' }}
                    className={ow`rounded`}
                    src={profileImage}
                    alt=""
                  />
                </p>
              )}
              <p className={ow`mb-5 text-lg font-bold text-gray-900 sm:mx-auto sm:max-w-md`}>
                {newUser ? 'Welcome' : 'Nice to see you'}, {username.split(' ')[0]}!
              </p>
              {hasError ? (
                <div
                  className={ow`mb-5 text-md text-gray-700 text-left sm:text-center sm:mx-auto sm:max-w-md `}
                >
                  Thank you for joining Recover Weekly!
                  <div className={ow`mt-2 font-semibold text-sm`}>
                    Unfortunately something has gone wrong <br /> while setting up your account
                  </div>
                </div>
              ) : hasConflicts ? (
                <div
                  className={ow`mb-5 text-md text-gray-700 text-left sm:text-center sm:mx-auto sm:max-w-md `}
                >
                  Thank you for joining Recover Weekly!
                  <div className={ow`mt-2 font-bold`}>
                    Oh no, it looks like you have multiple Discover Weekly playlists on your Spotify
                    account.
                  </div>
                </div>
              ) : newUser ? (
                <p className={ow`mb-5 text-sm text-gray-700 text-left sm:mx-auto sm:max-w-md `}>
                  Thank you for joining Recover Weekly, below is your timeline for recovering lost
                  playlists. We've added this weeks playlist below.
                  <br />
                  <br />
                  Every week we'll save your Discover Weekly so you can recover it back into your
                  Spotify account at anytime.
                  <br />
                  <br />
                  Happy listening and hope to see you back here next week to recover some great
                  tunes!
                </p>
              ) : (
                <p
                  className={ow`mb-5 text-sm text-gray-700 text-left sm:text-center sm:mx-auto sm:max-w-md `}
                >
                  We've got your backups, recover your <strong>Discover Weekly</strong> playlists!
                  <br />
                  Easily recover the Discover Weekly playlists from the past weeks.
                  <br />
                  <br />
                  <strong>New:</strong> Press any song in a playlist to listen to a preview
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
