import { useMemo, useReducer, useState } from 'react';
import { themed } from 'oceanwind';
import { API_URL } from '../App';
import { getWeekOfYear, getWeekDays } from '../utils/dates';
import Track from './Track';

const ow = themed({
  colors: {
    spot: '#1db954',
  },
});

interface PlaylistData {
  name: string;
  description: string;
  images: { width: number; url: string; height: number }[];
  last_updated: number;
  id: string;
  tracks: TrackInfo[];
}

interface TrackInfo {
  name: string;
  images: { width: number; url: string; height: number }[];
  id: string;
  artists: {
    name: string;
    href: string;
    id: string;
    type: string;
    external_urls: {
      spotify: string;
    };
    uri: string;
  }[];
  album_name: string;
  preview_url: string;
}

interface Props {
  playlists: PlaylistData[];
}

export default function Playlists({ playlists }: Props) {
  return (
    <main className={ow`px-4 py-4 mx-auto md:max-w-xl`}>
      {playlists.map((_, index) => (
        <Playlist key={index} playlists={playlists} index={index} />
      ))}
    </main>
  );
}

function Playlist({ playlists, index }: { playlists: PlaylistData[]; index: number }) {
  const [isExpanded, toggleExpanded] = useReducer(expanded => !expanded, false);
  const [recoveredUrl, setRecoveredUrl] = useState('unknown');
  const playlist = playlists[index];
  const thisWeeksYear = new Date(playlist.last_updated).getFullYear();
  const nextWeeksYear =
    playlists[index - 1] && new Date(playlists[index - 1].last_updated).getFullYear();

  const slicedTracks = useMemo(
    () => (isExpanded ? playlist.tracks : playlist.tracks.slice(0, 8)),
    [playlist.tracks, isExpanded]
  );

  return (
    <>
      {(index === 0 || thisWeeksYear < nextWeeksYear) && (
        <div className={ow`text-3xl`}>{new Date(playlist.last_updated).getFullYear()}</div>
      )}
      <div>
        <div
          className={ow`bg-white top-0 flex justify-between py-3`}
          style={{ position: 'sticky', zIndex: 10 }}
        >
          <div>
            <div className={ow`text-2xl`}>#{getWeekOfYear(playlist.last_updated)}</div>
            <div className={ow`text-sm`}>{getWeekDays(playlist.last_updated)}</div>
          </div>
          <div className={ow`flex items-center`}>
            {index === 0 ? (
              <a
                data-umami-event="open-current-playlist"
                className={ow`px-3 py-2 tracking-wide text-white rounded shadow-md bg-spot`}
                href={`https://open.spotify.com/playlist/${playlist.id}`}
                target="_blank"
                rel="noreferrer"
              >
                Listen
                <span className={ow`hidden md:inline`}> on Spotify</span>
              </a>
            ) : recoveredUrl.includes('spotify.com') ? (
              <a
                data-umami-event="open-recovered-playlist"
                data-umami-event-index={index}
                className={ow`px-3 py-2 tracking-wide text-white rounded shadow-md bg-spot`}
                href={recoveredUrl}
                target="_blank"
                rel="noreferrer"
              >
                Listen
                <span className={ow`hidden md:inline`}> on Spotify</span>
              </a>
            ) : (
              <button
                data-umami-event="recover-playlist"
                data-umami-event-index={index}
                onClick={() => {
                  setRecoveredUrl('pending');
                  fetch(API_URL + `/recover`, {
                    method: 'POST',
                    credentials: 'include',
                    body: JSON.stringify({ playlistId: playlist.last_updated }),
                  })
                    .then(res => res.json())
                    .then(({ playlist }) => setRecoveredUrl(playlist.external_urls.spotify));
                }}
                disabled={recoveredUrl === 'pending'}
                className={ow`px-3 py-2 tracking-wide text-white rounded shadow-md bg-spot`}
              >
                {recoveredUrl === 'pending' ? (
                  'Recovering...'
                ) : (
                  <>
                    Recover <span className={ow`hidden md:inline`}>Playlist</span>
                  </>
                )}
              </button>
            )}
          </div>
        </div>
        <div className={ow`relative grid gap-2 my-3`}>
          {slicedTracks.map((track, index) => {
            const findTrackImage = track.images.find(image => image.width === 64);
            return (
              <Track
                key={track.id}
                trackNumber={index + 1}
                image={findTrackImage?.url}
                trackName={track.name}
                artists={track.artists.map(artist => artist.name)}
                previewUrl={track.preview_url}
              />
            );
          })}
          {!isExpanded && (
            <div
              className={ow`absolute bottom-0 w-full flex justify-center items-center h-32`}
              style={{
                background:
                  '-webkit-linear-gradient(top, rgba(255,255,255,0.5), rgba(255,255,255, 1))',
              }}
            >
              <button
                data-umami-event="expand-playlist"
                data-umami-event-playlist-index={index}
                className={ow`inline-flex items-center justify-center bg-white hover:bg-gray-200 font-bold text-sm h-8 px-6 tracking-wide text-spot rounded shadow-md border-2 border-color-spot`}
                onClick={toggleExpanded}
              >
                View more...
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
