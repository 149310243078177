import ow from 'oceanwind';
import { useCallback, useEffect, useRef, useState } from 'react';
import { track } from '../utils/analytics';

interface Props {
  trackNumber: number;
  image?: string;
  trackName: string;
  artists: string[];
  previewUrl: string | undefined;
}

const audioMap = new Map<string, HTMLAudioElement>();

export default function Track({ trackNumber, image, trackName, artists, previewUrl }: Props) {
  const { play, pause, isPlaying, isLoaded, currentTime, duration } = useAudio(previewUrl!);
  const [hoverRef, isHovering] = useHover<HTMLButtonElement>();
  const togglePlaying = () => {
    if (isPlaying) {
      pause();
    } else {
      if (!isLoaded) {
        track('play-playlist-track');
      }
      play();
    }
  };
  return (
    <button
      className={ow`relative rounded-lg bg-gray-200 cursor-pointer overflow-hidden text-left`}
      ref={hoverRef}
      onClick={togglePlaying}
    >
      {isLoaded && (
        <div
          className={ow`absolute rounded-lg h-full w-full bg-blue-200 transition`}
          style={{
            transform: `translateX(${-100 + (currentTime / duration) * 100}%)`,
            transitionDuration: '0.6s',
          }}
        />
      )}
      <div className={ow`relative flex items-center px-3 py-2`}>
        <span className={ow`w-6 pl-1 pr-2`}>
          {isPlaying ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M2 6c0-1.886 0-2.828.586-3.414C3.172 2 4.114 2 6 2c1.886 0 2.828 0 3.414.586C10 3.172 10 4.114 10 6v12c0 1.886 0 2.828-.586 3.414C8.828 22 7.886 22 6 22c-1.886 0-2.828 0-3.414-.586C2 20.828 2 19.886 2 18V6ZM14 6c0-1.886 0-2.828.586-3.414C15.172 2 16.114 2 18 2c1.886 0 2.828 0 3.414.586C22 3.172 22 4.114 22 6v12c0 1.886 0 2.828-.586 3.414C20.828 22 19.886 22 18 22c-1.886 0-2.828 0-3.414-.586C14 20.828 14 19.886 14 18V6Z" />
            </svg>
          ) : !isHovering ? (
            trackNumber
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M21.409 9.353a2.998 2.998 0 0 1 0 5.294L8.597 21.614C6.534 22.736 4 21.276 4 18.968V5.033c0-2.31 2.534-3.769 4.597-2.648l12.812 6.968Z" />
            </svg>
          )}
        </span>
        <img className={ow`w-10 h-10 mx-3 rounded`} src={image} alt="" />
        <div>
          <p>{trackName}</p>
          <p className={ow`text-xs`}>{artists.join(', ')}</p>
        </div>
      </div>
    </button>
  );
}

export function useHover<T extends HTMLElement>() {
  const [hovering, setHovering] = useState(false);
  const ref = useRef<T>(null);

  useEffect(() => {
    const { current: node } = ref;

    if (!node) return;

    const handleMouseEnter = () => setHovering(true);
    const handleMouseLeave = () => setHovering(false);

    node.addEventListener('mouseenter', handleMouseEnter);
    node.addEventListener('mouseleave', handleMouseLeave);
    node.addEventListener('focus', handleMouseEnter);
    node.addEventListener('focusout', handleMouseLeave);

    return () => {
      node.removeEventListener('mouseenter', handleMouseEnter);
      node.removeEventListener('mouseleave', handleMouseLeave);
      node.removeEventListener('focus', handleMouseEnter);
      node.removeEventListener('focusout', handleMouseLeave);
    };
  }, []);

  return [ref, hovering] as const;
}

function useAudio(audioSrc: string) {
  const [duration, setDuration] = useState(100);
  const [currentTime, setCurrentTime] = useState(0);
  const [isLoaded, setLoaded] = useState(false);
  const [isPlaying, setPlaying] = useState(false);

  useEffect(() => {
    if (isLoaded) setLoaded(audioMap.has(audioSrc));
  }, [audioSrc]);

  const play = useCallback(() => {
    if (!audioMap.has(audioSrc)) {
      audioMap.set(audioSrc, new Audio(audioSrc));
      setLoaded(true);
    }
    const audio = audioMap.get(audioSrc);
    if (!audio) return;

    // Pause all other audio
    audioMap.forEach(a => a.pause());

    audio.play();
  }, [audioSrc]);

  const pause = useCallback(() => {
    const audio = audioMap.get(audioSrc);
    if (!audio) return;
    audio.pause();
  }, [audioSrc]);

  const setTime = useCallback(
    (time: number) => {
      const audio = audioMap.get(audioSrc);
      if (!audio) return;
      setCurrentTime(time);
      audio.currentTime = time;
    },
    [audioSrc]
  );

  useEffect(() => {
    const audio = audioMap.get(audioSrc);
    if (!isLoaded || !audio) return;

    const setStartedPlaying = () => setPlaying(true);
    const setStoppedPlaying = () => setPlaying(false);
    const setAudioTime = () => setCurrentTime(audio.currentTime);
    const setAudioData = () => {
      setDuration(audio.duration);
      setCurrentTime(audio.currentTime);
    };

    audio.addEventListener('play', setStartedPlaying);
    audio.addEventListener('pause', setStoppedPlaying);
    audio.addEventListener('ended', setStoppedPlaying);
    audio.addEventListener('timeupdate', setAudioTime);
    audio.addEventListener('loadeddata', setAudioData);

    return () => {
      audio.removeEventListener('play', setStartedPlaying);
      audio.removeEventListener('pause', setStoppedPlaying);
      audio.removeEventListener('ended', setStoppedPlaying);
      audio.removeEventListener('timeupdate', setAudioTime);
      audio.removeEventListener('loadeddata', setAudioData);
    };
  }, [isLoaded, audioSrc]);

  return {
    currentTime,
    duration,
    isPlaying,
    isLoaded,
    play,
    pause,
    setTime,
  };
}
