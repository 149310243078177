import { themed } from 'oceanwind';
const ow = themed({
  colors: {
    spot: '#1db954',
  },
});

interface ConflictedPlaylist {
  id: string;
  name: string;
  owner: { display_name: string };
  images: Array<{ width: null; url: string; height: null }>;
  external_urls: { spotify: string };
}

export default function PlaylistSelector({
  conflicts,
  onPlaylistSelected,
}: {
  conflicts: ConflictedPlaylist[];
  onPlaylistSelected: (id: string) => void;
}) {
  return (
    <main className={ow`px-4 pb-8 mx-auto md:max-w-xl`}>
      <div className={ow`text-center text-lg font-semibold`}>
        Select the playlist you would like to backup every week
      </div>
      <div className={ow`grid gap-4 my-3`}>
        {conflicts.map(({ id, name, owner, images, external_urls }) => (
          <div className={ow`flex items-center p-3 rounded-lg bg-gray-200`} key={id}>
            <img className={ow`w-1/3 mx-3 rounded`} src={images[0]?.url} alt="" width="120" />
            <div>
              <p className={ow`text-lg font-semibold`}>{name}</p>
              <p className={ow`hidden sm:block text-sm`}>Created by {owner.display_name}</p>
              <a
                className={ow`text-xs text-spot`}
                href={external_urls.spotify}
                target="_blank"
                rel="noopener noreferrer"
              >
                Open playlist in Spotify
              </a>
              <div className={ow`mt-5`}>
                <button
                  onClick={() => onPlaylistSelected(id)}
                  className={ow`text-sm px-3 py-2 tracking-wide text-white rounded shadow-md bg-spot`}
                >
                  Select playlist
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </main>
  );
}
