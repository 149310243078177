export function getWeekOfYear(date: Date | number) {
  if (!(date instanceof Date)) date = new Date(date);
  const oneDay = 86400000;
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  const dateMs = date.valueOf();
  const firstDayMs = firstDayOfYear.valueOf();
  return Math.ceil(((dateMs - firstDayMs) / oneDay + firstDayOfYear.getDay() + 1) / 7);
}

export function dateToLocalString(date: Date | number) {
  if (!(date instanceof Date)) date = new Date(date);
  return date.toLocaleString(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
}

export function getWeekDays(date: number) {
  const sixDays = 6 * 24 * 3600 * 1000;
  return `${dateToLocalString(date)} - ${dateToLocalString(date + sixDays)}`;
}
