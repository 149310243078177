import { useEffect, useState } from 'react';
import { themed } from 'oceanwind';

const ow = themed({
  colors: {
    spot: '#1db954',
  },
});

export default function Loading() {
  const [loadingText, setLoadingText] = useState('');
  useEffect(() => {
    const id = setTimeout(() => setLoadingText('Loading...'), 600);
    const id2 = setTimeout(() => setLoadingText('Fetching your playlists...'), 3000);
    const id3 = setTimeout(() => setLoadingText("Sorry, it's taking a while..."), 10000);
    return () => {
      clearTimeout(id);
      clearTimeout(id2);
      clearTimeout(id3);
    };
  }, []);
  return !loadingText.length ? null : ( // Starts at null for no flash of content for first second
    <div
      className={ow`fixed top-0 left-0 right-0 bottom-0 w-full h-screen flex flex-col items-center justify-center`}
    >
      <div
        className={
          'spinner ' + // animation keyframes
          ow`ease-linear rounded-full border-8 border-t-8 border-gray-400 h-16 w-16 mb-4 text-spot`
        }
      />
      <h2 className={ow`text-centertext-xl font-semibold`}>{loadingText}</h2>
    </div>
  );
}
