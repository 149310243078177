import { themed } from 'oceanwind';
import { useState } from 'react';
import { API_URL } from '../App';

const ow = themed({
  colors: {
    spot: '#1db954',
  },
});

export default function Login() {
  const [loading, setLoading] = useState(false);
  return (
    <div className={ow`w-full mx-auto`}>
      <div className={ow`relative flex flex-col items-center justify-center w-full mx-auto`}>
        <div className={ow`relative bg-gray-200 w-full`}>
          <div className={ow`absolute inset-x-0 bottom-0`}>
            <svg
              viewBox="0 0 224 12"
              fill="currentColor"
              preserveAspectRatio="none"
              className={ow`w-full -mb-1`}
            >
              <path
                fill="#fff"
                d="M0,0 C48.8902582,6.27314026 86.2235915,9.40971039 112,9.40971039 C137.776408,9.40971039 175.109742,6.27314026 224,0 L224,12.0441132 L0,12.0441132 L0,0 Z"
              ></path>
            </svg>
          </div>
          <div
            className={ow`px-4 py-16 mx-auto md:max-w-full max-w-screen-xl md:px-24 lg:px-8 lg:py-20`}
          >
            <div className={ow`relative max-w-2xl sm:mx-auto md:max-w-2xl sm:text-center`}>
              <h2
                className={ow`mb-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl sm:leading-none`}
              >
                Recover your Discover Weekly playlists from{' '}
                <span className={ow`text-spot`}>Spotify</span>
              </h2>
              <p className={ow`mb-6 text-base text-gray-700 md:text-lg`}>
                Automatically backup your Discover Weekly playlists so you can always recover lost
                music from previous weeks
              </p>
              <form className={ow`flex flex-col md:items-center w-full mb-4 md:flex-row md:px-16`}>
                <a
                  href={API_URL + '/auth'}
                  onClick={() => setLoading(true)}
                  data-umami-event="login-with-spotify"
                  className={ow([
                    loading ? 'pointer-events-none' : '',
                    'flex flex-grow items-center justify-center h-12 px-6 font-semibold tracking-widest text-white shadow-md bg-spot rounded-full uppercase',
                  ])}
                >
                  {loading ? 'Loading...' : 'Log in with Spotify'}
                </a>
              </form>
              <p className={ow`max-w-md mb-10 text-xs tracking-wide text-gray-700 sm:mx-auto`}>
                We respect your privacy and only access your Discover Weekly playlists.
              </p>
            </div>
          </div>
        </div>
      </div>
      <section
        id="faqs"
        className={ow`relative flex flex-col items-center justify-center w-full mx-auto`}
      >
        <div className={ow`px-4 py-16 bg-white mx-auto w-full md:px-24 lg:px-8 lg:py-20`}>
          <div className={ow`flex flex-col max-w-4xl mx-auto mb-6 md:mb-10`}>
            <div>
              <h2
                className={ow`max-w-md mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none xl:max-w-lg`}
              >
                What is Discover Weekly?
              </h2>
            </div>
            <div>
              <p className={ow`text-base text-gray-700 md:text-lg`}>
                <a
                  className={ow`font-bold text-spot`}
                  href="https://www.spotify.com/discoverweekly/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Discover Weekly
                </a>{' '}
                is a playlist that is curated for you by{' '}
                <a
                  className={ow`font-bold text-spot`}
                  href="https://www.spotify.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Spotify
                </a>
                . The playlist is updated every Monday, but it means you lose the music you had last
                week. We’re sometimes busy and/or forgetful, and some of us have definitely had
                regrets when we’ve lost our Discover Weekly. That’s why we built Recover Weekly, so
                you can access your Discover Weekly whenever you want.
              </p>
            </div>
          </div>
          <div className={ow`grid max-w-4xl gap-8 mx-auto sm:grid-cols-2 lg:grid-cols-2`}>
            <div className={ow`max-w-md`}>
              <h6 className={ow`mb-2 font-semibold leading-5 text-gray-900`}>
                When can I recover a past Discover Weekly?
              </h6>
              <p className={ow`mb-3 text-sm text-gray-900`}>
                Spotify updates your Discover Weekly playlist every week, on Monday. We’ll have it
                when it’s updated, and save it for you, so you can have access to it anytime. We can
                do this only once you’re logged in to Recover Weekly.
              </p>
            </div>
            <div className={ow`max-w-md`}>
              <h6 className={ow`mb-2 font-semibold leading-5 text-gray-900`}>
                How do I find old Discover Weekly playlists?
              </h6>
              <p className={ow`mb-3 text-sm text-gray-900`}>
                Once you log in with your Spotify account on Recover Weekly, your Discover Weekly
                playlists are saved, and ready for you to recover with a single click.
              </p>
            </div>
            <div className={ow`max-w-md`}>
              <h6 className={ow`mb-2 font-semibold leading-5 text-gray-900`}>
                I haven't used Recover Weekly yet and I've lost a song from my previous Discover
                Weekly?
              </h6>
              <p className={ow`mb-3 text-sm text-gray-900`}>
                Sadly, there is nothing you can do to get it back. Make sure you log in to Recover
                Weekly, and we’ll save it for you going forward.
              </p>
            </div>
            <div className={ow`max-w-md`}>
              <h6 className={ow`mb-2 font-semibold leading-5 text-gray-900`}>
                How long does Recover Weekly retain past playlists?
              </h6>
              <p className={ow`mb-3 text-sm text-gray-900`}>
                Recover Weekly currently saves the last 4 weeks of Discover Weekly playlists. We
                have this limit in place as it’s a free service and we need to keep our data costs
                low. Feel free to reach out to us when you’re logged in if you have any questions as
                we may be able to recover older playlists.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
